export class BurgerNavi {
    constructor() {
        this.menuButton = document.getElementById('menuButton');
        this.mainNavWrapper = document.querySelector('.main-nav');
    }

    init() {
        const self = this;
        this.menuButton.addEventListener('click', this.openNav.bind(this));
        /*Array.from(this.mainNavWrapper.querySelectorAll('li')).forEach(function (ne) {
            ne.addEventListener('click', function (e) {
                self.menuButton.classList.remove('is-active');
                self.mainNavWrapper.classList.remove('opened');
            });
        });*/
        window.addEventListener('scroll', this.closeNav.bind(this));
    }

    openNav(evt) {
        if (!this.menuButton.classList.contains('is-active')) {
            this.menuButton.classList.add('is-active');
            this.mainNavWrapper.classList.add('opened');
            //this.hideOnClickOutside('header#masthead');
        } else {
            this.menuButton.classList.remove('is-active');
            this.mainNavWrapper.classList.remove('opened');
        }
        if (evt != null) {
            evt.preventDefault();
        }
    }
    closeNav() {
        this.menuButton.classList.remove('is-active');
        this.mainNavWrapper.classList.remove('opened');
    }
    hideOnClickOutside(element) {
        let self = this;

        const outsideClickListener = event => {
            if (event.target.closest(element) === null) {
                if (self.mainNavWrapper.classList.contains('opened')) {
                    self.closeNav(null);
                    removeClickListener();
                }
            }
        };

        const removeClickListener = () => {
            document.removeEventListener('click', outsideClickListener);
        };

        document.addEventListener('click', outsideClickListener);
    }
}

