import { BaseFunctions } from "./js/basefunctions.js";
import { RevealItems } from "./js/revealItems.js";
import { ShrinkHeader } from "./js/shrinkheader.js";
import { BurgerNavi } from "./js/burgernavi.js";

const breakpointMedium = 768;
const breakpointML = 940;

document.addEventListener("DOMContentLoaded", function (event) {
    const basefunctions = new BaseFunctions(breakpointMedium);
    basefunctions.init();

    const burgernavi = new BurgerNavi(breakpointMedium);
    burgernavi.init();

    const revealitems = new RevealItems(breakpointMedium);
    revealitems.init();

    const shrinkheader = new ShrinkHeader(breakpointML);
    shrinkheader.init();
});