import { gsap } from "gsap/dist/gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger"

export class RevealItems {

    constructor(breakpointMedium) {
        this.breakpointMedium = breakpointMedium;
    }
    init() {
        gsap.registerPlugin(ScrollTrigger);

        let elemsText = gsap.utils.toArray(".page-template-wp-custom-template-textseite .column-layout, .page-template-wp-custom-template-about .column-layout, .page-template-wp-custom-template-projekte .column-layout");
        elemsText.forEach(elem => {
            elem.classList.add("no-reveal");
        });

        let startPoint = '95%';
        let delay = 0.1;

        /*let elemsLeft = gsap.utils.toArray(".wp-block-column:first-of-type .column-layout:not(.no-reveal) figure");
        elemsLeft.forEach(elem => {
            gsap.set(elem, { x: -30, alpha: 0 })
            gsap.fromTo(elem, { x: -30, alpha: 0 },
                {
                    scrollTrigger: {
                        trigger: elem,
                        start: "top " + startPoint,
                        //markers: true,
                        toggleActions: "play pause resume reset"
                    },
                    x: 0,
                    alpha: 1,
                    delay: delay,
                    ease: "power4.out",
                    duration: 1
                })
        });
        let elemsRight = gsap.utils.toArray(".wp-block-column:last-of-type .column-layout:not(.no-reveal) figure");
        elemsRight.forEach(elem => {
            gsap.set(elem, { x: 30, alpha: 0 })
            gsap.fromTo(elem, { x: 30, alpha: 0 },
                {
                    scrollTrigger: {
                        trigger: elem,
                        start: "top " + startPoint,
                        //markers: true,
                        toggleActions: "play pause resume reset"
                    },
                    x: 0,
                    alpha: 1,
                    delay: delay,
                    ease: "power4.out",
                    duration: 1
                })
        });*/
    
        let elemsBottom = gsap.utils.toArray(".column-layout:not(.no-reveal)");
        let dist = 20;
        delay = 0;
        if (window.innerWidth < this.breakpointMedium) {
            startPoint = '100%';
        }
        //dist = 50;
        elemsBottom.forEach(elem => {
            gsap.set(elem, { y: dist, alpha: 0 })
            gsap.fromTo(elem, { y: dist, alpha: 0 },
                {
                    scrollTrigger: {
                        trigger: elem,
                        start: -dist + " " + startPoint,
                        //markers: true,
                        toggleActions: "play pause resume reset",
                        //markers: true
                    },
                    y: 0,
                    alpha: 1,
                    delay: delay,
                    ease: "power.out",
                    duration: 1.5,
                })
        });

        /*let elemsFade = gsap.utils.toArray(".entry-content .image-columns figure");
        let startPoint = '90%';
        let delay = 0;
        elemsFade.forEach((elem, index) => {
            if (index == 0) {
                delay = 0;
                gsap.fromTo(elem, { alpha: 0 },
                    {
                        scrollTrigger: {
                            trigger: elem,
                            start: "-100px " + startPoint,
                        },
                        alpha: 1,
                        ease: "power2.out",
                        duration: 2,
                        delay: 0
                    });
            } else {
                gsap.set(elem, { alpha: 0 })
                gsap.fromTo(elem, { alpha: 0 },
                    {
                        scrollTrigger: {
                            trigger: elem,
                            start: "top " + startPoint,
                        },
                        x: 0,
                        alpha: 1,
                        delay: delay,
                        ease: "power2.out",
                        duration: 2
                    })
            }
            if (delay < 0.3) {
                delay += 0.15;
            } else {
                delay = 0;
            }
        });


        let elemsFade1 = gsap.utils.toArray(".headerimage figure");
        elemsFade1.forEach((elem, index) => {
            delay = 0;
            gsap.fromTo(elem, { alpha: 0 },
                {
                    scrollTrigger: {
                        trigger: elem,
                        start: "-100px " + startPoint,
                    },
                    alpha: 1,
                    ease: "power2.out",
                    duration: 0.8,
                    delay: 0
                });
        });


        let elemsBottom = gsap.utils.toArray(".content-section:not(.no-reveal) h2, .content-section:not(.no-reveal) p, .content-section:not(.no-reveal) ul");
        let dist = 20;
        startPoint = '95%';
        delay = 0;
        if (window.innerWidth < this.breakpointMedium) {
            dist = 20;
        } else {
            dist = 20;
        }
        elemsBottom.forEach(elem => {
            gsap.set(elem, { y: dist, alpha: 0 })
            gsap.fromTo(elem, { y: dist, alpha: 0 },
                {
                    scrollTrigger: {
                        trigger: elem,
                        start: -dist + " " + startPoint,
                    },
                    y: 0,
                    alpha: 1,
                    delay: delay,
                    ease: "power4.out",
                    duration: 1
                })
        });*/
    }
}