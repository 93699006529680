import { gsap } from "gsap";

export class ShrinkHeader {
    constructor(breakpointML) {
        this.breakpointML = breakpointML;
    }
    init() {
        window.addEventListener('scroll', this.shrink.bind(this));
    }
    shrink() {
        if (window.innerWidth >= this.breakpointML) {
            if (window.scrollY > 90) {
                document.querySelector('.site-header').classList.add('shrink');
            } else {
                document.querySelector('.site-header').classList.remove('shrink');
            }
        } else {
            document.querySelector('.site-header').classList.remove('shrink');
        }
    }
}